@font-face {
  font-family: 'Lato';
  src: url('/fonts/Lato-Black.ttf') format('truetype');
  font-display: swap;
  font-weight: 900;
}

@font-face {
  font-family: 'Lato';
  src: url('/fonts/Lato-Regular.ttf') format('truetype');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'Sole-Headline';
  src: url('/fonts/Sole-HeadlineBlack.otf') format('opentype');
  font-display: swap;
  font-weight: 900;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
